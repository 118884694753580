import { Formik, FormikValues } from 'formik'
import { autorun, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { isEmpty } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { Dish, Recipe, RecipeToIngredient, RecipeToRecipe, Unit } from 'api'
import { useReturnToPageContext } from 'app/contexts/ReturnToPage'
import { Grid, Loader } from 'components'
import {
  LeaveConfirmationModal,
  NewButton,
  NewLayout,
  NewTextField,
  Typography,
} from 'components/newUi'
import { Icon } from 'components/newUi/Icon'
import { FormState } from 'mobx/StateStore/StateStore'
import { routes } from 'routes/Paths'
import { SelectUnit } from 'screens/Create/Ingredients/components/SelectUnit/SelectUnit'
import { useUpdateComponent } from 'screens/Create/Ingredients/hooks'
import { RecipeOrIngredient } from 'screens/Create/Ingredients/types'
import { NewModal } from 'screens/Create/NewModal'
import { useTheme } from 'styles/newUi'
import { cleanErrorMessage, updatedData } from 'utils'
import {
  calculateRecipeIngredientPrice,
  calculateRecipeToRecipePrice,
} from 'utils/form/price'

import { Item } from './components/Item'
import { useStyles } from './styles'

import { errorToast, successToast } from '../../../components/toasts'
export { Grid } from '@mui/material'

export interface IProps {
  id: string
  ingredients: RecipeToIngredient[]
  recipes: RecipeToRecipe[]
}

const keySelector = (item: RecipeToRecipe | RecipeToIngredient | undefined) => {
  if (!item) return undefined
  if (item.__typename === 'RecipeToIngredient') {
    return item.ingredient.id
  } else {
    return (item as RecipeToRecipe).childRecipe.id
  }
}

const validationSchema = Yup.object({
  amount: Yup.number()
    .required('Required')
    .moreThan(0, 'Must be greater than 0'),
  components: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number()
        .required('Required')
        .moreThan(0, 'Must be greater than 0'),
      unit: Yup.object({
        id: Yup.number().required(),
        name: Yup.string().required(),
      }).required('Required'),
    }),
  ),
})

export const NewIngredients = observer(
  ({
    id,
    store,
    recipe,
    dish,
    isDish,
  }: {
    id: string
    store: FormState
    recipe: Recipe
    dish?: Dish
    isDish: boolean
  }) => {
    const classes = useStyles()
    const navigate = useNavigate()
    const returnToPage = useReturnToPageContext()
    const { theme } = useTheme()

    const [render, setRender] = useState(0)
    const [updateComponent] = useUpdateComponent(isDish)
    const [showModal, setShowModal] = useState(false)
    const [selectedIngredient, setSelectedIngredient] = useState<
      RecipeOrIngredient | undefined
    >(undefined)
    const [data, setData] = useState<RecipeOrIngredient[] | undefined>(
      undefined,
    )
    const [overrideFormConfirmation, setOverrideFormConfirmation] =
      useState(false)

    const itemRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      autorun(() => {
        setData([
          ...[...store.recipes.values()].map((i) => toJS(i)),
          ...[...store.ingredients.values()].map((i) => toJS(i)),
        ])
      })
    }, [store.recipes, store.ingredients])

    useEffect(() => {
      itemRef.current?.scrollIntoView()
    })

    const selectUnit = (selectedItem: RecipeOrIngredient) => {
      setSelectedIngredient(selectedItem)
      setShowModal(true)
    }

    const removeItem = (selectedItem: RecipeOrIngredient) => {
      store.removeIngredient(selectedItem)
    }

    const updateItem = (product: RecipeToRecipe | RecipeToIngredient) => {
      store?.updateIngredient(product)
    }

    const handleEditIngredient = (
      item: RecipeToRecipe | RecipeToIngredient,
    ) => {
      if (item.__typename === 'RecipeToIngredient') {
        setOverrideFormConfirmation(true)
        setTimeout(() => {
          returnToPage.saveRouteAndNavigate(
            `${routes.Product}${routes.Update}/${item.ingredient._cursor}`,
            false,
          )
        }, 0)
      }
    }

    const handleOnSubmit = async (state: FormikValues) => {
      const storeIngredients = Array.from(store.ingredients.values())
      const storeRecipes = Array.from(store.recipes.values())

      const removedIngredients: RecipeToIngredient[] = []
      const removedRecipes: RecipeToRecipe[] = []

      recipe.ingredients.forEach((ing) => {
        if (
          !storeIngredients.some(
            (i: { ingredient: { id: number } }) =>
              i.ingredient.id === ing.ingredient.id,
          )
        ) {
          removedIngredients.push(ing)
        }
      })

      recipe.childRecipes.forEach((reci) => {
        if (
          !storeRecipes.some(
            (i: { childRecipe: { id: number } }) =>
              i.childRecipe.id === reci.childRecipe.id,
          )
        ) {
          removedRecipes.push(reci)
        }
      })

      const updatedIngredients = recipe.ingredients.reduce(
        (prev: RecipeToIngredient[], cur) => {
          const temp = storeIngredients.find(
            (st) => st.ingredient.id === cur.ingredient.id,
          )

          if (temp) {
            const updated = updatedData(temp, cur)

            if (!isEmpty(updated)) {
              prev.push(temp)
            }
          }

          return prev
        },
        [],
      )

      const updatedRecipes = recipe.childRecipes.reduce(
        (prev: RecipeToRecipe[], cur) => {
          const temp = storeRecipes.find(
            (st) => st.childRecipe.id === cur.childRecipe.id,
          )

          if (temp) {
            const updated = updatedData(temp, cur)

            if (!isEmpty(updated)) {
              prev.push(temp)
            }
          }

          return prev
        },
        [],
      )

      const newRecipes = storeRecipes.filter(
        (x) =>
          !recipe.childRecipes.some(
            (y) => y.childRecipe.id === x.childRecipe.id,
          ),
      )

      const newIngredients = storeIngredients.filter(
        (x) =>
          !recipe.ingredients.some((y) => y.ingredient.id === x.ingredient.id),
      )

      await updateComponent({
        variables: {
          childRecipes: transformRecipes(newRecipes),
          data: {
            amount: !isDish ? state.amount : undefined,
            id: Number(id),
            portion: isDish ? state.amount : undefined,
            unitId: !isDish ? state.unitId : undefined,
          },
          ingredients: transformIngredients(newIngredients),
          removeChildRecipes: removedRecipes.map((reci) => reci.childRecipe.id),
          removeIngredients: removedIngredients.map((ing) => ing.ingredient.id),
          updateChildRecipes: transformRecipes(updatedRecipes),
          updateIngredients: transformIngredients(updatedIngredients),
        },
      })
        .then(({ data }) => {
          // @ts-ignore
          const { name } = (data?.updateOneRecipe ??
            // @ts-ignore
            data?.updateOneDish) as Recipe
          successToast(`Saved ${name}`)
          // setOverrideFormConfirmation(true);

          // reset the form state.
          runInAction(() => {
            store.isDirty = false
          })

          navigate(-1)
        })
        .catch((error) => errorToast(cleanErrorMessage(error.message)))

      return
    }

    if (!data) return <Loader />

    const initialValues = {
      amount: (!isDish ? recipe.amount : dish?.portion) ?? 0,
      components: data ?? [],
      unit: recipe?.unit?.name ?? undefined,
      unitId: recipe?.unit?.id ?? undefined,
    }

    const totalCost = data
      .reduce((acc, item) => {
        if (item.__typename === 'RecipeToRecipe') {
          acc += calculateRecipeToRecipePrice(item)
        }

        if (item.__typename === 'RecipeToIngredient') {
          acc += calculateRecipeIngredientPrice(item)
        }

        return acc
      }, 0)
      ?.toFixed(2)

    function onKeyDown(keyEvent: React.KeyboardEvent<unknown>) {
      if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault()
      }
    }

    return (
      <>
        <NewLayout title="Ingredients" subtitle={recipe.name} />

        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <form
              className={classes._root}
              onKeyDown={onKeyDown}
              style={{ backgroundColor: 'white', display: 'flex', flex: 1 }}
            >
              <LeaveConfirmationModal
                action="leave"
                title="You have unsaved changes, are you sure you want to leave the screen?"
                text=""
                onConfirm={() => {
                  formik.resetForm()
                  store.init(recipe.childRecipes, recipe.ingredients, true)
                  navigate(-1)
                }}
                show={
                  overrideFormConfirmation === false &&
                  store.isDirty &&
                  !formik.isSubmitting
                }
              />
              <div
                style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
              >
                {!data.length && (
                  <div
                    style={{
                      alignItems: 'flex-end',
                      display: 'flex',
                      flexDirection: 'row',
                      height: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{
                          paddingBottom: 16,
                          textAlign: 'center',
                          verticalAlign: 'center',
                        }}
                      >
                        Add your ingredients for this dish
                      </Typography>
                      <NewButton
                        text="Add Ingredient"
                        style={{
                          backgroundColor: 'white',
                          color: theme.palette.primary[100].toHexString(),
                          paddingLeft: 10,
                          paddingRight: 10,
                          width: 160,
                        }}
                        preventSubmission
                        disabled={formik.isSubmitting}
                        loading={formik.isSubmitting}
                        onClick={() => {
                          setOverrideFormConfirmation(true)

                          setTimeout(() => {
                            returnToPage.saveRouteAndNavigate(
                              `${routes.Product}${routes.List}`,
                            )
                          }, 0)
                        }}
                      >
                        <Icon iconName="add" style={{ paddingLeft: 4 }} />
                      </NewButton>
                    </div>
                  </div>
                )}
                <div className={classes.list}>
                  {data.map((item, index) => {
                    return (
                      <Item
                        itemRef={
                          keySelector(item) ===
                          keySelector(
                            store.lastUpdated as
                              | Required<RecipeToRecipe>
                              | Required<RecipeToIngredient>,
                          )
                            ? itemRef
                            : undefined
                        }
                        render={render}
                        index={index}
                        key={keySelector(item)}
                        item={item}
                        handleSelectUnit={selectUnit}
                        handleEditIngredient={handleEditIngredient}
                        removeItem={removeItem}
                        updateItem={updateItem}
                      />
                    )
                  })}
                </div>
                <div className={classes.mobileButtonWrap}>
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'flex-start !important',
                      width: '100% !important',
                    }}
                  >
                    <Typography
                      variant="caption"
                      style={{ color: theme.palette.primary[80].toHexString() }}
                    >
                      Makes
                    </Typography>
                    <NewTextField
                      hideNumberControls
                      defaultValue={formik.values.amount}
                      error={formik.errors.amount}
                      errorStyle={{
                        display: 'none',
                      }}
                      inputClassName={classes.inputInner}
                      inputProps={{
                        'data-hj-allow': '',
                        'data-testid': 'gpTargetInput',
                        style: {
                          textAlign: 'center',
                          width: '70px',
                        },
                      }}
                      onChange={(value) => {
                        formik.setFieldValue(
                          'amount',
                          Number(parseFloat(value as unknown as string)),
                        )
                      }}
                      placeholder={!isDish ? 'Yield' : 'Serving'}
                      style={{
                        marginLeft: 5,
                        marginRight: 5,
                        width: 60,
                      }}
                      type="number"
                    />
                    {!isDish && (
                      <NewButton
                        text={String(formik.values.unit)}
                        preventSubmission={true}
                        onClick={() => {
                          setShowModal(true)
                        }}
                        className={classes.unitButtonFooter}
                      >
                        <Icon
                          iconName="swapHoriz"
                          style={{
                            color: theme.palette.primary[40].toHexString(),
                            paddingLeft: 4,
                          }}
                        />
                      </NewButton>
                    )}
                    {isDish && (
                      <Typography variant="caption" style={{ paddingLeft: 8 }}>
                        Servings
                      </Typography>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        color: theme.palette.primary[80].toHexString(),
                        textAlign: 'right',
                      }}
                      testId="total-cost"
                    >
                      £{totalCost}
                    </Typography>
                    <Typography
                      testId="cost-per-serving"
                      variant="caption"
                      style={{ textAlign: 'right' }}
                    >
                      £
                      {isNaN(
                        (totalCost as unknown as number) / formik.values.amount,
                      )
                        ? 0.0
                        : (
                            (totalCost as unknown as number) /
                            formik.values.amount
                          ).toFixed(2)}
                      / serving
                    </Typography>
                  </div>
                </div>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    backgroundColor: theme.palette.secondary[100].toHexString(),
                    display: 'flex',
                    justifyContent: 'center',
                    maxHeight: 64,
                  }}
                  className={classes.mobileButtonWrap}
                >
                  <NewButton
                    text="Add Ingredient"
                    style={{
                      backgroundColor: 'white',
                      color: theme.palette.primary[100].toHexString(),
                      paddingLeft: 10,
                      paddingRight: 10,
                      width: 160,
                    }}
                    preventSubmission
                    data-testid="addIngredientsButton"
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    onClick={() => {
                      setOverrideFormConfirmation(true)

                      setTimeout(() => {
                        returnToPage.saveRouteAndNavigate(
                          `${routes.Product}${routes.List}`,
                        )
                      }, 0)
                    }}
                  >
                    <Icon iconName="add" style={{ paddingLeft: 4 }} />
                  </NewButton>

                  <NewButton
                    data-testid="recipe-submit"
                    disabled={
                      (!store.isDirty && !formik.dirty) || formik.isSubmitting
                    }
                    text={formik.isSubmitting ? undefined : 'Save'}
                    disableLoaderStyles
                    loading={formik.isSubmitting}
                    style={{
                      width: 160,
                    }}
                    type="button"
                    onClick={() => {
                      formik.handleSubmit()
                      setRender(render + 1)
                      if (formik.errors.amount) {
                        errorToast(
                          'To save this, please enter the missing information',
                        )
                      }
                    }}
                  />
                </Grid>
              </div>
              <NewModal
                title="Change Unit"
                handleCancel={() => {
                  setSelectedIngredient(undefined)
                  setShowModal(false)
                }}
                show={showModal}
              >
                {showModal && (
                  <SelectUnit
                    ingredient={selectedIngredient}
                    isRecipe={!selectedIngredient}
                    recipe={recipe}
                    handleUpdateUnit={(
                      unit: Unit,
                      ingredient?: RecipeOrIngredient,
                    ) => {
                      if (ingredient) {
                        const temp = {
                          ...ingredient,
                          unit: unit,
                          unitId: unit.id,
                        }
                        store.updateIngredient(temp)
                        setSelectedIngredient(undefined)
                      } else {
                        formik.setFieldValue('unitId', unit.id)
                        formik.setFieldValue('unit', unit.name)
                      }
                      setShowModal(false)
                    }}
                  />
                )}
              </NewModal>
            </form>
          )}
        </Formik>
      </>
    )
  },
)

export const transformIngredients = (items: RecipeToIngredient[]) => {
  return items.map((ingredient) => ({
    amount: ingredient.amount,
    id: ingredient.ingredient.id,
    unitCost: ingredient.unitCost!,
    unitId: Number(ingredient.unitId ?? ingredient.unit?.id),
  }))
}

export const transformRecipes = (items: RecipeToRecipe[]) => {
  return items.map((recipe) => ({
    amount: recipe.amount,
    id: recipe.childRecipe.id,
    unitCost: recipe.unitCost,
    unitId: Number(
      recipe.unitId ?? recipe.unit?.id ?? recipe?.childRecipe?.unit?.id,
    ),
  }))
}
