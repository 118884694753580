import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { rootReducer } from './reducers'

const kitchenVersion = 2

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    transforms: [
      createTransform(
        (inboundState, key) => {
          // Add version to all kitchen objects.
          if (key === 'kitchen') {
            return { ...inboundState, _persist: { version: kitchenVersion } }
          }

          return inboundState
        },
        (outboundState, key) => {
          if (key !== 'kitchen') {
            return outboundState
          }

          // If version does not match the current version, invalidate.
          if (outboundState._persist?.version !== kitchenVersion) {
            return undefined
          }

          return outboundState
        },
        { whitelist: ['kitchen'] },
      ),
    ],
    version: 1,
    whitelist: [
      'auth',
      'authFlow',
      'kitchen',
      'pendingOrders',
      'marketing',
      'stocktake',
    ],
  },
  rootReducer,
)

export const store = configureStore({
  devTools: true,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  reducer: persistedReducer,
})

export type RootState = ReturnType<typeof rootReducer>
