import { useMutation } from '@apollo/client'
import { Button, InfoAlert, Typography } from '@getjelly/jelly-ui'
import { Box } from '@mui/material'
import { IconChevronDown, IconChevronUp, IconX } from '@tabler/icons-react'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { omit } from 'ramda'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import {
  Ingredient,
  Kitchen,
  Mutation,
  MutationUpdateOneIngredientArgs,
  ProductStatus,
  Supplier,
} from 'api'
import { useReturnToPageContext } from 'app/contexts/ReturnToPage'
import { NewLayout, NewSelectUnit, NewTextField } from 'components/newUi'
import { SelectSupplier } from 'components/newUi/SelectSupplier'
import { useWindowSize } from 'hooks'
import { updateIngredientMutation } from 'screens/Ingredient/graphql'
import { theme } from 'styles/newUi'
import { cleanErrorMessage, isEmptyOrNil } from 'utils'

import { useStyles } from './styles'

import { MoneyInput } from '../../../components/Inputs/MoneyInput'
import { errorToast, successToast } from '../../../components/toasts'
import { useNewStateStore } from '../../../mobx/StateStore/Meta'

export const Form = ({
  kitchen,
  supplier,
  ingredient,
}: {
  kitchen?: Kitchen
  supplier?: Supplier
  ingredient?: Ingredient
}) => {
  const { getFormState } = useNewStateStore()
  const { savedRoute } = useReturnToPageContext()
  const { gte: isDesktop } = useWindowSize('md')

  const navigate = useNavigate()
  const classes = useStyles()
  const [showOptional, setShowOptional] = useState(false)
  const [conversionUnitLabel, setConversionUnitLabel] = useState('unit')

  const [updateIngredient, { loading }] = useMutation<
    { updateOneIngredient: Mutation['updateOneIngredient'] },
    MutationUpdateOneIngredientArgs
  >(updateIngredientMutation)

  const [showSelectSupplier, setShowSelectSupplier] = useState(true)

  const formik = useFormik({
    initialValues: ingredient
      ? {
          code: ingredient.product?.code ?? '',
          conversionUnit: ingredient.conversionUnit?.toString(),
          conversionUnitTypeId: ingredient.conversionUnitType?.id,
          conversionUnitValue: ingredient.conversionUnitValue?.toString(),
          name: ingredient.product?.name,
          packSize: ingredient.product?.packSize,
          price: ingredient.price || 0,
          supplierId: ingredient.product?.supplier.id,
          supplierName: ingredient.product?.supplier.name,
          unit: ingredient.product?.unit,
          unitId: ingredient.product?.unit.id,
          unitName: ingredient.product.unit.name,
          unitValue: ingredient.product?.unitValue,
          wastage: ingredient.wastage,
        }
      : {
          code: undefined,
          conversionUnit: undefined,
          conversionUnitTypeId: undefined,
          conversionUnitValue: undefined,
          name: '',
          packSize: 1,
          price: undefined,
          productId: undefined,
          supplierId: supplier ? supplier.id : undefined,
          supplierName: supplier ? supplier.name : undefined,
          unitId: undefined,
          unitPrice: 0,
          unitValue: undefined,
          wastage: 0,
        },
    isInitialValid: false,
    onSubmit: async (data) => {
      if (
        requiresUnitConversion &&
        (!data.conversionUnit ||
          !data.conversionUnitValue ||
          !data.conversionUnitTypeId)
      ) {
        formik.setFieldError('conversionUnit', 'Required')
        formik.setFieldError('conversionUnitValue', 'Required')
        formik.setFieldError('conversionUnitTypeId', 'Please select unit')

        return
      }

      if (
        (!isEmptyOrNil(data.conversionUnit) &&
          (isEmptyOrNil(data.conversionUnitTypeId) ||
            isEmptyOrNil(data.conversionUnitValue))) ||
        (!isEmptyOrNil(data.conversionUnitTypeId) &&
          (isEmptyOrNil(data.conversionUnit) ||
            isEmptyOrNil(data.conversionUnitValue))) ||
        (!isEmptyOrNil(data.conversionUnitValue) &&
          (isEmptyOrNil(data.conversionUnitTypeId) ||
            isEmptyOrNil(data.conversionUnit)))
      ) {
        const dirty =
          data.conversionUnit !== formik.initialValues.conversionUnit ||
          data.conversionUnitValue !==
            formik.initialValues.conversionUnitValue ||
          data.conversionUnitTypeId !==
            formik.initialValues.conversionUnitTypeId

        if (!data.conversionUnit && dirty) {
          formik.setFieldError('conversionUnit', 'Required')
        }

        if (!data.conversionUnitValue && dirty) {
          formik.setFieldError('conversionUnitValue', 'Required')
        }

        if (!data.conversionUnitTypeId && dirty) {
          formik.setFieldError('conversionUnitTypeId', 'Please select unit')
        }

        if (
          (!data.conversionUnit ||
            !data.conversionUnitValue ||
            !data.conversionUnitTypeId) &&
          dirty
        ) {
          return
        }
      }

      const { supplierName, supplierId, productId, ...rest } = data

      if (!supplierName) {
        errorToast('No supplier.')
        return
      }

      const hasUpdatedSupplierId =
        data.supplierId !== ingredient?.product.supplier.id

      const toSend = {
        data: {
          ...omit(['unit', 'unitName'], rest),
          conversionUnit: parseFloat(rest.conversionUnit || ''),
          conversionUnitValue: parseFloat(rest.conversionUnitValue || ''),
          id: ingredient?.id as number,
          unitId: data.unitId,
        },
        kitchenId: kitchen!.id,
        supplierData: showSelectSupplier ? undefined : { name: supplierName },
        supplierId:
          showSelectSupplier && hasUpdatedSupplierId ? supplierId : undefined,
      }

      try {
        const { data: updateData } = await updateIngredient({
          variables: toSend,
        })
        if (!updateData) return

        if (savedRoute) {
          const formState = getFormState(savedRoute)

          formState?.updateIngredient(updateData.updateOneIngredient)
        }

        navigate(-1)
      } catch (error) {
        errorToast(cleanErrorMessage((error as Error).message))
      }
    },
    validationSchema: Yup.object({
      conversionUnit: Yup.number().nullable(),
      conversionUnitTypeId: Yup.number().nullable(),
      conversionUnitValue: Yup.number().nullable(),
      name: Yup.string().required('Required'),
      packSize: Yup.number()
        .required('Required')
        .min(0.000001, 'Must be greater than 0.000001'),
      price: Yup.number().min(0).required('Required'),
      productId: Yup.string().nullable(),
      supplierId: Yup.string().nullable(),
      unitId: Yup.number().required('Required'),
      unitValue: Yup.number()
        .required('Required')
        .min(0.000001, 'Must be greater than 0.000001'),
      wastage: Yup.number()
        .min(0, 'Must be greater than or equal to 0 and less than 100')
        .max(101, 'Must be greater than or equal to 0 and less than 100'),
    }),
  })

  const [requiresUnitConversion, setRequiresUnitConversion] = useState(false)
  const isFromCatalogue = ingredient?.product.status === ProductStatus.Available
  const initialUnit = ingredient?.product.unit

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <div className="flex flex-1 flex-col">
        <div className={classes.root}>
          <NewLayout
            title="Edit product"
            subtitle="Spending"
            rightContent={
              isDesktop ? (
                <div className="flex space-x-2">
                  <Button
                    style="secondary"
                    label="Cancel"
                    disabled={loading}
                    loading={loading}
                    onClick={() => navigate(-1)}
                  />

                  <Button
                    disabled={loading}
                    loading={loading}
                    onClick={() => formik.handleSubmit()}
                    label="Save Changes"
                  />
                </div>
              ) : undefined
            }
          />

          <div className={classes.innerWrap}>
            <Box>
              {isFromCatalogue && (
                <InfoAlert text="As this is a catalogue product some details can't be changed." />
              )}

              <div className="flex items-center bg-primary-100 py-2 px-4">
                <Typography style="button" className="text-primary-600">
                  Product details
                </Typography>
              </div>

              <div
                style={{
                  borderBottomColor: theme.palette.primary[10].toHexString(),
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                  padding: theme.spacing(2),
                  paddingBottom: 4,
                }}
              >
                {!showSelectSupplier && (
                  <NewTextField
                    inputProps={{
                      'data-hj-allow': '',
                    }}
                    label="Supplier"
                    placeholder="Enter a Supplier Name..."
                    defaultValue={formik.values.supplierName}
                    disabled={!!supplier}
                    className={classes.supplierName}
                    onChange={(value) =>
                      formik.setFieldValue('supplierName', value)
                    }
                    error={
                      !!(
                        formik.touched.supplierName &&
                        formik.errors.supplierName
                      )
                    }
                  />
                )}

                {showSelectSupplier && kitchen && (
                  <SelectSupplier
                    kitchenId={kitchen.id}
                    className={classes.supplierName}
                    label="Supplier"
                    style={{
                      margin: 0,
                      marginBottom: 9,
                      marginTop: 9,
                      maxWidth: 'calc(100vw - 24px)',
                      padding: 0,
                    }}
                    placeholder=""
                    disabled={isFromCatalogue}
                    defaultValue={formik.values.supplierId}
                    onChange={(value) => {
                      if (value === 'NEW') {
                        setShowSelectSupplier(false)
                        formik.setFieldValue('supplierName', '')
                        return
                      }

                      formik.setFieldValue('supplierId', value)
                    }}
                    error={
                      !!(formik.touched.supplierId && formik.errors.supplierId)
                    }
                    required={showSelectSupplier}
                  />
                )}
              </div>

              <div className={classes.boxWithPointer}>
                <NewTextField
                  inputProps={{
                    'data-hj-allow': '',
                    'data-testid': 'ingredientNameField',
                  }}
                  disabled={isFromCatalogue}
                  label="Product Name"
                  placeholder="Enter a Product Name..."
                  style={{ paddingTop: 0, width: '100%' }}
                  required
                  defaultValue={formik.values.name}
                  onChange={(value) => formik.setFieldValue('name', value)}
                  error={!!(formik.touched.name && formik.errors.name)}
                />
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                    paddingRight: 8,
                    width: '100%',
                  }}
                >
                  <div style={{ flex: 7 }} className={classes.packAmountWrap}>
                    <NewTextField
                      min="0.000001"
                      inputProps={{
                        'data-hj-allow': '',
                        step: '0.000001',
                      }}
                      disabled={isFromCatalogue}
                      style={{ flex: 3, paddingBottom: 3 }}
                      label="Pack Size"
                      type="number"
                      hideNumberControls
                      required
                      defaultValue={formik.values.packSize}
                      onChange={(value) =>
                        formik.setFieldValue('packSize', value)
                      }
                      error={
                        !!(formik.touched.packSize && formik.errors.packSize)
                      }
                    />

                    <div className="flex items-end h-14 px-1">
                      <IconX />
                    </div>

                    <NewTextField
                      min="0.000001"
                      inputProps={{
                        'data-hj-allow': '',
                        step: '0.000001',
                      }}
                      disabled={isFromCatalogue}
                      style={{ flex: 3, paddingBottom: 3 }}
                      label="Unit Value"
                      type="number"
                      hideNumberControls
                      required
                      defaultValue={formik.values.unitValue}
                      onChange={(value) =>
                        formik.setFieldValue('unitValue', value)
                      }
                      error={
                        !!(formik.touched.unitValue && formik.errors.unitValue)
                      }
                    />
                  </div>

                  <NewSelectUnit
                    label="Unit"
                    style={{ flex: 3, marginBottom: 9 }}
                    placeholder=""
                    disabled={isFromCatalogue}
                    defaultValue={formik.values.unitId}
                    showPortion
                    onChange={(value, label, data) => {
                      formik.setFieldValue('unitId', value)

                      setConversionUnitLabel(
                        data.type === 'custom' ? label : 'unit',
                      )

                      if (
                        (data.type === 'custom' &&
                          initialUnit?.type !== 'custom') ||
                        (data.type !== 'custom' &&
                          initialUnit?.type === 'custom')
                      ) {
                        setRequiresUnitConversion(true)

                        document.getElementById('amountInputField')?.focus()
                      } else {
                        setRequiresUnitConversion(false)
                      }
                    }}
                    error={!!(formik.touched.unitId && formik.errors.unitId)}
                    required
                  />
                </div>
              </div>

              {!requiresUnitConversion && (
                <div
                  onClick={(e) => {
                    e.preventDefault()
                    setShowOptional(!showOptional)
                  }}
                  className="flex items-center justify-between bg-primary-100 py-2 px-4 cursor-pointer"
                >
                  <Typography style="button" className="text-primary-600">
                    Advanced options
                  </Typography>

                  {showOptional ? <IconChevronUp /> : <IconChevronDown />}
                </div>
              )}

              <div
                className={clsx([
                  classes.hideableFields,
                  (showOptional || requiresUnitConversion) && classes.show,
                ])}
                style={{
                  paddingLeft: theme.spacing(2),
                  paddingRight: theme.spacing(2),
                }}
              >
                <div className="py-4">
                  <NewTextField
                    inputProps={{
                      'data-hj-allow': '',
                    }}
                    disabled={isFromCatalogue}
                    label="Product code (Optional)"
                    style={{ width: '100%' }}
                    hideNumberControls
                    defaultValue={formik.values.code}
                    className={classes.productCode}
                    onChange={(value) => formik.setFieldValue('code', value)}
                    error={!!(formik.touched.code && formik.errors.code)}
                  />

                  <NewTextField
                    inputProps={{
                      'data-hj-allow': '',
                    }}
                    min="0"
                    label="Waste % (Optional)"
                    style={{ width: '100%' }}
                    placeholder="Enter percentage of waste..."
                    type="number"
                    hideNumberControls
                    endAdornment="%"
                    defaultValue={formik.values.wastage}
                    onChange={(value) => formik.setFieldValue('wastage', value)}
                    error={!!(formik.touched.wastage && formik.errors.wastage)}
                  />

                  {formik.errors.wastage && (
                    <Typography style="body2" className={classes.errorMessage}>
                      {formik.errors.wastage}
                    </Typography>
                  )}
                </div>

                <div className="space-y-6 py-4 border-t border-primary-100">
                  <div className="text-primary-900 space-y-2">
                    <Typography style="subtitle1">Unit Conversion</Typography>

                    <Typography style="body2">
                      To get accurate costings, please specify how many grams
                      per unit, if known.
                    </Typography>
                  </div>

                  <Box className={classes.packAmountWrap} gap={2}>
                    <NewTextField
                      inputProps={{
                        'data-hj-allow': '',
                        id: 'amountInputField',
                        step: '0.01',
                      }}
                      min="0"
                      style={{ flex: 3 }}
                      label="Amount"
                      type="number"
                      endAdornment={conversionUnitLabel}
                      required={requiresUnitConversion}
                      hideNumberControls
                      defaultValue={formik.values.conversionUnit}
                      onChange={(value) =>
                        formik.setFieldValue('conversionUnit', value)
                      }
                      error={
                        !!(
                          formik.touched.conversionUnit &&
                          formik.errors.conversionUnit
                        )
                      }
                    />

                    <div className={classes.equals}>=</div>

                    <NewTextField
                      inputProps={{
                        'data-hj-allow': '',
                        step: '0.000001',
                      }}
                      min="0"
                      style={{ flex: 3 }}
                      label="Value"
                      type="number"
                      hideNumberControls
                      required={requiresUnitConversion}
                      defaultValue={formik.values.conversionUnitValue}
                      onChange={(value) =>
                        formik.setFieldValue('conversionUnitValue', value)
                      }
                      error={
                        !!(
                          formik.touched.conversionUnitValue &&
                          formik.errors.conversionUnitValue
                        )
                      }
                    />

                    <NewSelectUnit
                      hideCustom
                      label="Unit"
                      style={{ flex: 3, margin: 0, padding: '10px 0' }}
                      placeholder=""
                      defaultValue={formik.values.conversionUnitTypeId}
                      onChange={(value) =>
                        formik.setFieldValue('conversionUnitTypeId', value)
                      }
                      className={classes.selectUnit}
                      error={
                        !!(
                          formik.touched.conversionUnitTypeId &&
                          formik.errors.conversionUnitTypeId
                        )
                      }
                    />
                  </Box>
                </div>
              </div>
            </Box>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between space-x-1 py-2 px-3 bg-secondary-300">
            <div>
              <Typography style="subtitle2" className="text-primary-900">
                Pack price
              </Typography>

              <Typography style="body2" className="text-primary-900">
                Approx. how much you last paid for this
              </Typography>
            </div>

            <div className="w-28 shrink-0">
              <MoneyInput
                min={0}
                error={
                  formik.touched.price && formik.errors.price ? '' : undefined
                }
                value={formik.values.price || null}
                onChange={(money) => formik.setFieldValue('price', money)}
              />
            </div>
          </div>

          {!isDesktop && (
            <div className="flex space-x-2 py-4 px-2 bg-white">
              <Button
                style="secondary"
                label="Cancel"
                disabled={loading}
                loading={loading}
                onClick={() => navigate(-1)}
                className="w-full"
              />

              <Button
                disabled={loading}
                loading={loading}
                onClick={() => formik.handleSubmit()}
                label="Save Changes"
                className="w-full"
              />
            </div>
          )}
        </div>
      </div>
    </form>
  )
}
