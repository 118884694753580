import { useMutation } from '@apollo/client'
import {
  Button,
  DateInput,
  DropdownInput,
  TextInput,
  Typography,
} from '@getjelly/jelly-ui'
import { IconHash } from '@tabler/icons-react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { routes } from 'routes/Paths'

import { invoiceSummaryUpdateMutation } from './graphql'

import {
  DocumentType,
  Mutation,
  MutationInvoiceSummaryUpdateArgs,
  useGetInvoiceByIdQuery,
} from '../../../../../api'
import { useKitchen } from '../../../../../app/contexts/SelectedKitchen'
import { MoneyInput } from '../../../../../components/Inputs/MoneyInput'
import { NewLayout } from '../../../../../components/newUi'
import { errorToast, successToast } from '../../../../../components/toasts'
import { documentTypeDisplay } from '../../../../../utils/text/documentTypeDisplay'

export function EditInvoice() {
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()

  const id = parseInt(useParams().id)

  const { data } = useGetInvoiceByIdQuery({
    skip: !id,
    variables: { where: { id } },
  })

  const [saving, setSaving] = useState(false)

  const [documentTypeError, setDocumentTypeError] = useState<
    string | undefined
  >()
  const [invoiceDateError, setInvoiceDateError] = useState<string | undefined>()

  const [documentType, setDocumentType] = useState(
    data?.invoice?.documentType ?? null,
  )
  const [invoiceId, setInvoiceId] = useState(data?.invoice?.invoiceId ?? '')
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(
    data?.invoice
      ? data.invoice.invoiceDate
        ? new Date(data.invoice.invoiceDate)
        : new Date(data.invoice.pendingInvoice?.createdAt)
      : null,
  )
  const [invoiceTotal, setInvoiceTotal] = useState<number | null>(
    data?.invoice?.invoiceTotal ?? 0,
  )
  const [vat, setVat] = useState<number | null>(data?.invoice?.vat ?? 0)

  useEffect(() => {
    setDocumentType(data?.invoice?.documentType ?? null)
    setInvoiceId(data?.invoice?.invoiceId ?? '')
    setInvoiceDate(
      data?.invoice
        ? data.invoice.invoiceDate
          ? new Date(data.invoice.invoiceDate)
          : new Date(data.invoice.pendingInvoice?.createdAt)
        : null,
    )
    setInvoiceTotal(data?.invoice?.invoiceTotal ?? 0)
    setVat(data?.invoice?.vat ?? 0)
  }, [data])

  const [updateInvoice] = useMutation<
    { invoiceSummaryUpdate: Mutation['invoiceSummaryUpdate'] },
    MutationInvoiceSummaryUpdateArgs
  >(invoiceSummaryUpdateMutation)

  async function saveInvoice() {
    if (saving) {
      return
    }

    if (!data?.invoice) {
      errorToast('Unknown error occurred.')
      return
    }

    setDocumentTypeError(undefined)
    setInvoiceDateError(undefined)

    if (documentType === null) {
      setDocumentTypeError('This is required.')
      return
    }

    if (invoiceDate === null) {
      setInvoiceDateError('This is required.')
      return
    }

    setSaving(true)

    try {
      await updateInvoice({
        variables: {
          documentType,
          id: data.invoice.id,
          invoiceDate: format(invoiceDate, 'yyyy-MM-dd'),
          invoiceId: invoiceId.trim() === '' ? null : invoiceId.trim(),
          invoiceTotal: invoiceTotal ?? 0,
          vat: vat ?? 0,
        },
      })

      navigate(routes.Spending + routes.Invoice + routes.History + `/${id}`)

      successToast('Successfully saved.')
    } catch {
      errorToast('Error saving invoice, please try again later.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <NewLayout
        hideMenu
        subtitle="Spending"
        title={`Document ${data?.invoice?.invoiceId ?? ''}`}
        bottomContent={
          <div className="px-2 py-4 space-x-2 flex border-t border-gray-200">
            <Button
              className="w-full"
              style="secondary"
              onClick={() => navigate(-1)}
              label="Cancel"
            />

            <Button
              className="w-full"
              onClick={saveInvoice}
              label="Save"
              loading={saving}
            />
          </div>
        }
      />

      <div className="px-4 py-6 space-y-6">
        <div className="space-y-2">
          <Typography className="text-primary-900" style="caption">
            Supplier
          </Typography>

          <DropdownInput
            disabled={true}
            value={data?.invoice?.supplier.name ?? ''}
            options={[data?.invoice?.supplier.name ?? '']}
            optionToId={(o) => o}
            optionToLabel={(o) => o}
            onChange={() => void 0}
          />
        </div>

        <div className="space-y-2">
          <Typography className="text-primary-900" style="caption">
            Location
          </Typography>

          <DropdownInput
            disabled={true}
            value={selectedKitchen?.name ?? ''}
            options={[selectedKitchen?.name ?? '']}
            optionToId={(o) => o}
            optionToLabel={(o) => o}
            onChange={() => void 0}
          />
        </div>

        <div className="space-y-2">
          <Typography className="text-primary-900" style="caption">
            Document type
          </Typography>

          <DropdownInput
            placeholder="Document type"
            value={documentType}
            error={documentTypeError || undefined}
            options={[
              DocumentType.Invoice,
              DocumentType.CreditNote,
              DocumentType.DeliveryNote,
              DocumentType.PurchaseOrder,
              DocumentType.Statement,
            ]}
            optionToId={(o) => o}
            optionToLabel={documentTypeDisplay}
            onChange={(o) => {
              if (
                o === DocumentType.CreditNote &&
                documentType !== DocumentType.CreditNote
              ) {
                setInvoiceTotal(-Math.abs(invoiceTotal || 0))
                setVat(-Math.abs(vat || 0))
              } else {
                setInvoiceTotal(Math.abs(invoiceTotal || 0))
                setVat(Math.abs(vat || 0))
              }

              setDocumentType(o)
            }}
          />
        </div>

        <div className="space-y-2">
          <Typography className="text-primary-900" style="caption">
            {documentType ? documentTypeDisplay(documentType) : 'Document'}{' '}
            number
          </Typography>

          <TextInput
            leftIcon={IconHash}
            value={invoiceId}
            onChange={setInvoiceId}
          />
        </div>

        <div className="space-y-2">
          <Typography className="text-primary-900" style="caption">
            {documentType ? documentTypeDisplay(documentType) : 'Document'} date
          </Typography>

          <DateInput
            error={invoiceDateError}
            value={invoiceDate}
            onChange={setInvoiceDate}
          />
        </div>

        <div className="space-y-2">
          <Typography className="text-primary-900" style="caption">
            VAT
          </Typography>

          <MoneyInput placeholder="VAT" value={vat} onChange={setVat} />
        </div>

        <div className="space-y-2">
          <Typography className="text-primary-900" style="caption">
            Total inc. tax
          </Typography>

          <MoneyInput
            placeholder="Total"
            value={invoiceTotal}
            onChange={setInvoiceTotal}
          />
        </div>
      </div>
    </>
  )
}
