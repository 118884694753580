import { Ingredient, UnitType } from 'api'
import { UnitFragmentFragment } from 'api/types'
import { GRAMS_PER_UNIT, UNITS_PER_GRAM } from 'app/constants'
import { IngredientType, RecipeType } from 'screens/Create/helpers/types'
import { StockTakeEntry } from 'store/stocktake'
import { calculateRTICost } from 'utils/costing'

export const IngredientPriceSelector = (ingredient: Ingredient) =>
  ingredient.wasteAdjustedUnitPrice ||
  ingredient.price ||
  ingredient.product?.price ||
  0

export const formatAsCurrency = (value: number): string => {
  return `£${value?.toFixed(2)}`
}

type NotFullUnit = UnitFragmentFragment & { [key: string]: any }

export const formatItemCost = (
  value: number,
  amount: number | undefined | null,
): string => {
  if (amount === undefined || amount === 0) return '£0.00'

  return formatAsCurrency(minimumCost(value))
}

export const minimumCost = (value: number) =>
  value >= 0 && value < 0.01 ? 0.01 : value

export const calculateRecipeToRecipePrice = (link: RecipeType) => {
  const { childRecipe: recipe } = link

  if (link.amount === undefined || link.amount === 0) return 0

  if (!recipe.unit || !recipe.unitCost || !link.unit || !recipe.amount)
    return 0.01

  return minimumCost(
    calculatePrice(
      recipe.unit,
      recipe.unitCost / recipe.amount,
      link.amount!,
      link.unit,
    ),
  )
}

declare global {
  interface Number {
    round: (places: number) => number
  }
}

// eslint-disable-next-line no-extend-native
Number.prototype.round = function (places: number): number {
  return Number(this?.toFixed(places))
}

export const wasteAdjustUnitPrice = (unitPrice: number, waste: number) =>
  unitPrice / (1 - waste / 100)

export const calculateStockEntryPrice = (entry: StockTakeEntry) => {
  const { ingredient, recipe } = entry

  if (!recipe && !ingredient) return

  if (recipe) {
    if (entry.quantity === undefined || entry.quantity === 0) return 0

    if (!recipe.unit || !recipe.unitCost || !entry.unit || !recipe.amount)
      return 0.01

    return minimumCost(
      calculatePrice(
        recipe.unit,
        recipe.unitCost / recipe.amount,
        entry.quantity!,
        entry.unit,
      ),
    )
  }

  // just for TS
  if (!ingredient) return

  if (entry.quantity === undefined || entry.quantity === 0) return 0

  const unitPrice =
    ingredient.wasteAdjustedUnitPrice ??
    wasteAdjustUnitPrice(ingredient.unitPrice!, ingredient.wastage)

  const tempPrice =
    entry.price ?? ingredient?.price ?? ingredient.product?.price

  const calculatedUnitPrice = tempPrice
    ? tempPrice /
      ingredient.product!.unitValue /
      (ingredient.product.packSize ?? 1)
    : null

  if (
    !ingredient.product?.unit ||
    (!unitPrice && !calculatedUnitPrice) ||
    !entry.unit
  ) {
    return 0.01 // min price
  }

  const requiresUnitConversion =
    ingredient.product?.unit.type === UnitType.Custom ||
    entry.unit.type === UnitType.Custom

  const conversionToGrams = ingredient?.conversionUnitType
    ? ingredient.conversionUnitType.gramsPerUnit
    : 0

  return calculatePrice(
    ingredient.product?.unit,
    calculatedUnitPrice!,
    entry.quantity!,
    entry.unit,
    requiresUnitConversion
      ? ingredient.conversionUnitValue! * conversionToGrams!
      : undefined,
    requiresUnitConversion ? ingredient.conversionUnit! : undefined,
  )
}

export const calculateRecipeIngredientPrice = (link: IngredientType) => {
  const { ingredient } = link

  return calculateRTICost(link, ingredient)
}

export interface IPriceCalculationProps {
  defaultUnit: NotFullUnit
  unitPrice: number
  newAmount: number
  newUnit: NotFullUnit
  conversionGrams?: number
  conversionUnits?: number
}

export const newCalculatePrice = (props: IPriceCalculationProps) => {
  const {
    defaultUnit,
    unitPrice,
    newAmount,
    newUnit,
    conversionGrams,
    conversionUnits,
  } = props

  const amountInDefaultUnits = getAmountInDefaultUnits(
    newAmount,
    newUnit,
    defaultUnit,
    conversionGrams,
    conversionUnits,
  )

  return (unitPrice * amountInDefaultUnits).round(2)
}

export const calculatePrice = (
  defaultUnit: NotFullUnit,
  unitPrice: number,
  newAmount: number,
  newUnit: NotFullUnit,
  conversionGrams?: number,
  conversionUnits?: number,
) => {
  const amountInDefaultUnits = getAmountInDefaultUnits(
    newAmount,
    newUnit,
    defaultUnit,
    conversionGrams,
    conversionUnits,
  )

  return unitPrice * amountInDefaultUnits
}

export const getAmountInDefaultUnits = (
  amount: number,
  from: NotFullUnit,
  to: NotFullUnit,
  conversionGrams: number | undefined,
  conversionUnits: number | undefined,
) => {
  const hasInvalidInputs = !amount || amount < 0 || !from || !to
  if (hasInvalidInputs) return 0

  const conversionRatio = calculateConversionRatio(
    conversionGrams,
    conversionUnits,
    from,
    to,
  )

  return amount * conversionRatio
}

export const calculateConversionRatio = (
  conversionGrams: number | undefined,
  conversionUnits: number | undefined,
  from: NotFullUnit,
  to: NotFullUnit,
) => {
  if (from.id === to.id) return 1

  let conversionFrom: number
  let conversionTo: number

  if (from.type === UnitType.Custom && conversionGrams && conversionUnits) {
    conversionFrom = conversionGrams / conversionUnits
  } else {
    conversionFrom = from.gramsPerUnit
      ? from.gramsPerUnit
      : GRAMS_PER_UNIT[from.name]
  }

  if (to.type === UnitType.Custom && conversionGrams && conversionUnits) {
    conversionTo = conversionUnits / conversionGrams
  } else {
    conversionTo = to.unitsPerGram ? to.unitsPerGram : UNITS_PER_GRAM[to.name]
  }

  if (conversionFrom === undefined || conversionTo === undefined) {
    return 0
  }

  return conversionFrom * conversionTo
}

export const decimalToPercent = (value: number) => (value ? value * 100 : 0)

export const percentToDecimal = (value: number) => value / 100

export const roundToTwoDecimalPlaces = (value: number) =>
  parseFloat(value?.toFixed(2))

export const calculateGrossProfit = (
  menuPrice: number,
  overheads: number,
  recipeCost: number,
  vat: number,
) => {
  if (menuPrice === undefined || menuPrice === 0) return 0
  const ov = overheads || 0
  const va = vat || 0
  const overheadsDecimal = percentToDecimal(ov)
  const vatDecimal = percentToDecimal(va)

  const grossProfit =
    1 -
    (recipeCost +
      recipeCost * vatDecimal +
      recipeCost * overheadsDecimal +
      recipeCost * vatDecimal * overheadsDecimal) /
      menuPrice

  return decimalToPercent(grossProfit)
}

export const calculateMenuPrice = (
  grossProfit: number,
  overheads: number,
  recipeCost: number,
  vat: number,
) => {
  if (recipeCost === undefined) return 0
  const grossProfitDecimal =
    Number.isNaN(grossProfit) || grossProfit === undefined || grossProfit >= 100
      ? 0
      : percentToDecimal(grossProfit)

  const overheadsDecimal =
    Number.isNaN(overheads) || overheads === undefined
      ? 0
      : percentToDecimal(overheads)

  const vatDecimal =
    Number.isNaN(vat) || vat === undefined ? 0 : percentToDecimal(vat)

  const menuPrice =
    (recipeCost / (1 - grossProfitDecimal)) *
    (1 + vatDecimal) *
    (1 + overheadsDecimal)

  return menuPrice
}

export interface IDishCost {
  gpTarget: number
  overheads: number
  recipeCost: number
  vat: number
}

export const newCalculateMenuPrice = ({
  gpTarget,
  overheads,
  recipeCost,
  vat,
}: IDishCost) => {
  if (recipeCost === undefined) return 0
  const grossProfitDecimal =
    Number.isNaN(gpTarget) || gpTarget === undefined || gpTarget >= 100
      ? 0
      : percentToDecimal(gpTarget)

  const overheadsDecimal =
    Number.isNaN(overheads) || overheads === undefined
      ? 0
      : percentToDecimal(overheads)

  const vatDecimal =
    Number.isNaN(vat) || vat === undefined ? 0 : percentToDecimal(vat)

  const menuPrice =
    (recipeCost / (1 - grossProfitDecimal)) *
    (1 + vatDecimal) *
    (1 + overheadsDecimal)

  return menuPrice
}
