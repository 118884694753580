import { Ingredient, Maybe, Product, RecipeToIngredient, Unit } from 'api'
import { GRAMS_PER_UNIT, UNITS_PER_GRAM } from 'app'

function getAmountInDefaultUnits(
  amount: number,
  from: Unit,
  to: Unit,
  conversionGrams?: number,
  conversionUnits?: number,
) {
  if (amount <= 0) {
    return 0
  }

  if (from.id === to.id) {
    return amount
  }

  let conversionFrom: number
  let conversionTo: number

  if (from.type === 'custom' && conversionGrams && conversionUnits) {
    conversionFrom = conversionGrams / conversionUnits
  } else {
    conversionFrom = from.gramsPerUnit
      ? from.gramsPerUnit
      : GRAMS_PER_UNIT[from.name]
  }

  if (to.type === 'custom' && conversionGrams && conversionUnits) {
    conversionTo = conversionUnits / conversionGrams
  } else {
    conversionTo = to.unitsPerGram ? to.unitsPerGram : UNITS_PER_GRAM[to.name]
  }

  return amount * (conversionFrom * conversionTo)
}

function wasteAdjustUnitPrice(
  unitPrice: number | null | undefined,
  wastage: number,
) {
  if (!unitPrice) return 0
  if (wastage >= 100) return 0

  return unitPrice / (1 - wastage / 100)
}

function ingredientUnitPrice(
  ingredient: Pick<
    Ingredient,
    'conversionUnitValue' | 'conversionUnit' | 'unitPrice' | 'wastage' | 'price'
  > & {
    product: Pick<Product, 'unitValue' | 'price' | 'packSize'> & { unit: Unit }
    conversionUnitType?: Maybe<Unit>
  },
) {
  const price = ingredient.price ?? ingredient.product.price

  const calculatedUnitPrice = price
    ? price / (ingredient.product.unitValue * ingredient.product.packSize)
    : null

  const wasteAdjusted = wasteAdjustUnitPrice(
    ingredient.unitPrice,
    ingredient.wastage,
  )

  return wasteAdjusted || calculatedUnitPrice
}

export function calculateRTICost(
  rti: {
    amount: RecipeToIngredient['amount'] | undefined
    unit?: Maybe<Unit>
  },
  ingredient: Pick<
    Ingredient,
    'conversionUnitValue' | 'conversionUnit' | 'unitPrice' | 'wastage' | 'price'
  > & {
    product: Pick<Product, 'unitValue' | 'price' | 'packSize'> & { unit: Unit }
    conversionUnitType?: Maybe<Unit>
  },
) {
  if (!rti.amount) {
    return 0
  }

  const unitPrice = ingredientUnitPrice(ingredient)

  if (!rti.unit || !unitPrice) {
    return 0.01
  }

  let amountInDefaultUnits: number
  if (ingredient.product.unit.type !== 'custom' && rti.unit.type !== 'custom') {
    amountInDefaultUnits = getAmountInDefaultUnits(
      rti.amount,
      rti.unit,
      ingredient.product.unit,
    )
  } else {
    const conversionToGrams = ingredient.conversionUnitType
      ? ingredient.conversionUnitType.gramsPerUnit
      : 0

    amountInDefaultUnits = getAmountInDefaultUnits(
      rti.amount,
      rti.unit,
      ingredient.product.unit,
      (ingredient.conversionUnitValue ?? 0) * (conversionToGrams ?? 0),
      ingredient.conversionUnit ?? 0,
    )
  }

  const total = unitPrice * amountInDefaultUnits

  if (!total) {
    return 0.01
  }

  return total
}
